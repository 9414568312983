import React from "react"
import PortableText from "@sanity/block-content-to-react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import {H1, P} from "../components/Typography"
import {GatsbyImage} from 'gatsby-plugin-image'
import styled from "styled-components"
import * as Sections from "../components/SectionElements"
import serializer from "../components/NonArticleSerializer"
import RndmImgSection from "../components/rndmImgSection"
import SEO from "../components/seo"

const EntityName = styled(P)`
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;
    margin-right: 2px;
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.foreground};
    margin-bottom: ${props => props.theme.spacings.small};
    margin-top: 0;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 4;
        grid-row: 1 / span 1;
        margin-top: ${props => props.theme.spacings.largest};
    }
`
const EntityContributions = styled.ol`
    list-style: none;
    counter-reset: custom-counter;

    grid-column: 1 / span 6;
    grid-row: 2 / span 1;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 3;
    }
`

const ListItem = styled.li`
    font-size: 12px;
    font-weight: 350;
    line-height: 1.5;
    position: relative;
    counter-increment: custom-counter;
    padding-bottom: ${props => props.theme.spacings.small};
    padding-left: 50px;

    :before {
        content: counter(custom-counter);
        color: ${props => props.theme.colors.foreground};
        font-size: 12px;
        position: absolute;
        top: 0;
        left: 0px;
        line-height: 1;
        text-indent: 0;
        padding-top: ${props => props.theme.spacings.xxSmall};
        padding-bottom: ${props => props.theme.spacings.xxSmall};
        padding-left: ${props => props.theme.spacings.xSmall};
        padding-right: ${props => props.theme.spacings.xSmall};
        background-color: ${props => props.theme.colors.gray2};
        border-radius: 5px;
        text-align: center;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        padding-left: 50px;
        :before {
            left: 0px;
            text-indent: 0px;
        }
    }
`

const ChairOutput = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;
    padding-bottom: ${props => props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(6,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 2 / span 6;
    }
`
const ChairOutputs = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: ${props => props.theme.spacings.largest};

    grid-column: 1 / span 4;
    grid-row: 2 / span 1;
    margin-bottom: ${props => props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 4;
    }
`
const LegoChaire = styled.div`
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    display: block;
    border-radius: 5px;
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.foreground};
    margin-right:2px;
    margin-bottom: 2px;
    pointer-events: none;
    font-size: 12px;
    font-weight: 375;

    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    margin-bottom: ${props => props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 4;
    }
`

const LegoOutput = styled.div`
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    display: block;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.gray2};
    margin-right:2px;
    margin-bottom: 2px;
    pointer-events: none;
    font-size: 12px;
    font-weight: 375;
`

const PartnerLogo = styled.div`
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
    width: 50%;
    margin-bottom: 50px;
    width: 50%;
    transform-origin: top left;
    transition: transform 0.3s;
  
    :hover {
      transform: scale(1.2,1.2);
      transform-origin: top left;
      transition: transform 0.3s;
    }
  
    @media ${props => props.theme.breakpoints.desktop} {
        margin-bottom: 100px;
    }
`
const SectionTitle = styled.div`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  padding-bottom: 0;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 1 / span 1;
    grid-column: 1 / span 12;
`
const BackDark = styled.span`
  font-size: 12px;
  font-weight: 375;
  padding-top: ${props => props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.theme.spacings.xxSmall};
  padding-left: ${props => props.theme.spacings.xSmall};
  padding-right: ${props => props.theme.spacings.xSmall};
  border-radius: 5px;
  margin-right: 2px;
  margin-bottom: 2px;
  color: ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.foreground};
`

const AboutPage = ({data}) => {
    const AboutNode = data.allSanityAbout.nodes[0]
    const PartnersNodes = data.allSanityPartner.nodes

    return (
        <Layout>
            <SEO 
            title="À propos"
            />
            <SectionTitle>
                <BackDark>
                    &Agrave; propos
                </BackDark>
            </SectionTitle>
            <RndmImgSection rowSpan="5"/>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={AboutNode._rawTagline}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={AboutNode._rawMainthemes}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={AboutNode._rawObjectives}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={AboutNode._rawBudget}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
            <Sections.ThreeSection>
                {AboutNode.externalentities.map((entity, i) => {
                    return (
                        <Sections.ThreeSectionDiv key={i}>
                            <EntityName context="smalltext">{entity.entityName}</EntityName>
                            <EntityContributions context="smalltext">
                                {entity.entityContributions.map((contribution, j) => {
                                    return (
                                        <ListItem key={j}>{contribution}</ListItem>
                                    )
                                })}
                            </EntityContributions>
                        </Sections.ThreeSectionDiv>
                    )
                })}
            </Sections.ThreeSection>
            <Sections.Section>
                <ChairOutput>
                    <LegoChaire>Chaire PcEn</LegoChaire>
                    <ChairOutputs>
                        <LegoOutput>Recherche</LegoOutput>
                        <LegoOutput>Publications</LegoOutput>
                        <LegoOutput>Cr&eacute;ation d'outils</LegoOutput>
                        <LegoOutput>Masterclasses</LegoOutput>
                        <LegoOutput>Colloques</LegoOutput>
                        <LegoOutput>D&eacute;bats</LegoOutput>
                    </ChairOutputs>
                </ChairOutput>
            </Sections.Section>
            <Sections.Section>
                <Sections.Text>
                    <PortableText
                    blocks={AboutNode._rawPartnersintro}
                    serializers = {serializer}
                    />
                </Sections.Text>
            </Sections.Section>
            <Sections.SixSection>
                {PartnersNodes.map((partner, i) => {
                    return (
                        <Sections.SixSectionDiv key={i}>
                            <PartnerLogo>
                                <a href={partner.website} target='_blank' rel='noopener noreferrer' alt={partner.company}><GatsbyImage image={partner.logo.asset.gatsbyImageData} alt=""/></a>
                            </PartnerLogo>
                        </Sections.SixSectionDiv>
                    )
                })}
            </Sections.SixSection>
        </Layout>
    )
} 

export default AboutPage

export const pageQuery = graphql`
    query AboutQuery {
        allSanityAbout {
            nodes {
                _rawBudget(resolveReferences: {maxDepth: 10})
                _rawTagline(resolveReferences: {maxDepth: 10})
                _rawObjectives
                _rawMainthemes
                _rawPartnersintro
                externalentities {
                    entityName
                    entityContributions
                }
            }
        }
        allSanityPartner(sort: {fields: company, order: ASC}) {
            nodes {
              company
              website
              logo {
                asset {
                    gatsbyImageData(width: 400)
                }
              }
            }
        }
        allSanityPost(sort: {fields: publishedAt, order: DESC}, skip: 0, limit: 5) {
            nodes {
                mainImage {
                    asset {
                        gatsbyImageData(width: 800)
                    }
                }
            }
        }
    }
`